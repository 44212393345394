import { isValidPhoneNumber } from 'libphonenumber-js'

const required = {
  required: true,
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (!value) {
      callback(new Error('El campo es requerido'))
    } else {
      callback()
    }
  },
}

const phone = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value && !isValidPhoneNumber(value, 'AR')) {
      callback(new Error('El teléfono no es válido'))
    } else {
      callback()
    }
  },
}

const address = {
  type: 'Object',
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (!value) {
      callback(new Error('La dirección es requerida'))
    } else if (!value.lat || !value.lng) {
      callback(new Error('La dirección está incompleta'))
    } else {
      callback()
    }
  },
}

const email = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value && !isValidEmail(value)) {
      callback(new Error('El email no es válido'))
    } else {
      callback()
    }
  },
}

const number = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value && isNaN(value)) {
      callback(new Error('El número no es válido'))
    } else {
      callback()
    }
  },
}

const url = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    try {
      const url = new URL(value)
      callback()
      return url
    } catch (err) {
      callback(new Error('La url no es válida'))
    }
  },
}

const money = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value && !/^\d+$/.test(value)) {
      callback(new Error('El monto no es válido'))
    } else {
      callback()
    }
  },
}

const name = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value && !/^[\w\s]{1,20}$/.test(value)) {
      callback(new Error('El nombre no es válido'))
    } else {
      callback()
    }
  },
}

const ref = {
  trigger: 'change',
  validator: (rule, value, callback) => {
    if (value.length > 16) {
      callback(new Error('El código es demasiado largo'))
    } else if (!/^[a-zA-Z0-9_-]{1,16}$/.test(value)) {
      callback(new Error('El código no puede contener carácteres especiales'))
    } else {
      callback()
    }
  },
}

const isValidEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export default {
  required,
  email,
  phone,
  address,
  number,
  name,
  money,
  ref,
  url,
}
